import React, { useEffect, useState } from "react";
import ProfileEditForm from "./ProfileEditForm";
import { getUserProfile } from "../../../services/salesforce/Account/user";
import { getAuthUser } from "../../../services/salesforce/Auth";
import LoaderIcon from "../../../images/icons/loader";
import "./styles.scss";

const ProfileInfo = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const initProfileData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneMobile: "",
    customerId: ""
  };
  const [profileData, setProfileData] = useState(initProfileData);

  const switchEditMode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsEdit(!isEdit);
  };

  const getProfileData = () => {
    const user = getAuthUser();
    const profileDataPromise = getUserProfile(user.rcid || "");
    profileDataPromise.then(data => {
      setProfileData(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    getProfileData();
  }, [isEdit]);

  if (isEdit && profileData) {
    return (
      <ProfileEditForm
        firstName={profileData.firstName}
        lastName={profileData.lastName}
        email={profileData.email}
        phoneMobile={profileData.phoneMobile}
        customerId={profileData.customerId}
        onUpdateProfile={() => setIsEdit(false)}
        onUpdateCancel={() => setIsEdit(false)}
      />
    );
  }

  return (
    <div className="person-details">
      {isLoading ? (
        <LoaderIcon width={100} height={100} className="loaderIcon" />
      ) : profileData ? (
        <div className="person-details__wrapper">
          <div className="person-details__user-info">
            <div className="user-name">
              {profileData.firstName} {profileData.lastName}
            </div>
            {profileData.email ? <div className="user-email">{profileData.email}</div> : ""}
            {profileData.phoneMobile ? <div className="user-phone">{profileData.phoneMobile}</div> : ""}
          </div>
          <div className="row person-details-buttons">
            <button
              type="button"
              onClick={switchEditMode}
              className="person-details__edit button button-nonary button-form-br-50 tertiary button-size-sm"
            >
              Edit
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProfileInfo;
