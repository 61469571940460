import React, { useContext } from "react";

import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import Button from "../../Button";
import Field from "../../Forms/Field";
import { LocalizedContext } from "../../../services/LocalizedContextService";
import { updateUserProfile } from "../../../services/salesforce/Account/user";
import { sendErrorEvents } from "../../../utils/sendErrorEvents";

const ProfileEditForm = (
  { firstName, lastName, email, phoneMobile, customerId, onUpdateProfile, onUpdateCancel }: any = {
    firstName: "",
    lastName: "",
    email: "",
    phoneMobile: "",
    customerId: ""
  }
) => {
  const { ctaLabels } = useContext(LocalizedContext).sanityLabels || {};
  const formLabels = useContext(LocalizedContext).sanityLabels?.formsLabels;
  const formPlaceholders = useContext(LocalizedContext).sanityLabels?.formPlaceholders;
  const errorMessages = useContext(LocalizedContext).sanityLabels?.errorMessages;

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateUserProfile,
    onSuccess: data => {
      queryClient.setQueryData("profile", data);
      onUpdateProfile(data);
    }
  });

  const onSubmit = (data: any) => {
    mutate({ ...data, customerId });
  };

  const PHONE_REGEX = new RegExp(/^\+?\d{0,1}\s?-?\(?(\d{3})\)?\s?-?(\d{3})\s?-?(\d{4})$/);
  const handlePhoneNumberValidate = (phoneNumber: any) => {
    return PHONE_REGEX.test(phoneNumber);
  };

  const formFields = [
    {
      type: "text",
      name: "firstName",
      label: formLabels?.firstName,
      defaultValue: firstName,
      placeholder: "Enter your first name",
      rules: {
        required: true,
        pattern: {
          value: /^[A-Za-z]+$/i,
          message: "First name should not contain any numbers, special symbols"
        },
        maxLength: {
          value: 50,
          message: "First name should not exceed more than 50 characters"
        }
      },
      message: errorMessages?.validFirstName || "Please, enter your first name"
    },
    {
      type: "text",
      name: "lastName",
      label: formLabels?.lastName,
      defaultValue: lastName,
      placeholder: "Enter your last name",
      rules: {
        required: true,
        pattern: {
          value: /^[A-Za-z]+$/i,
          message: "Last name should not contain any numbers, special symbols"
        },
        maxLength: {
          value: 50,
          message: "Last name should not exceed more than 50 characters"
        }
      },
      message: errorMessages?.validLastName || "Please, enter your last name"
    },
    {
      type: "text",
      name: "email",
      label: formLabels?.email + " (Used for mailing, username can’t be changed)",
      defaultValue: email,
      rules: {
        required: true,
        pattern: {
          value:
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: errorMessages?.validEmail
        }
      },
      message: errorMessages?.validEmail || "Please, enter your email"
    },
    {
      type: "text",
      name: "phone",
      label: formLabels?.phone,
      placeholder: formPlaceholders?.phone,
      defaultValue: phoneMobile,
      rules: {
        required: true,
        validate: (value: any) => {
          if (!handlePhoneNumberValidate(value)) {
            return "Invalid phone number. Please try again.";
          }
        },
        minLength: {
          value: 10,
          message: "Phone number should be at least 10 digits"
        }
      },
      message: errorMessages?.validPhone || "Please enter your phone number!"
    }
  ];

  const handleSubmitClick = () => {
    sendErrorEvents(errors, formFields);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="person-details__wrapper">
      {formFields.map((field: any, index: number) => (
        <div className="row" key={index}>
          <Field
            className="input-text"
            key={field.name}
            {...field}
            registerInput={register}
            errors={errors}
            message={field.message}
          />
        </div>
      ))}

      <div className="row">
        <div className="col-12 person-details-buttons">
          <Button onClick={onUpdateCancel} variant="nonary" form="br-50" className="person-details__cancel">
            {ctaLabels?.cancel}
          </Button>
          <Button
            className="person-details__submit"
            type="submit"
            variant="nonary"
            form="br-50"
            disabled={isLoading}
            onClick={handleSubmitClick}
          >
            {ctaLabels?.save}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ProfileEditForm;
